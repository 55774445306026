<template>
  <div>
    <div class="flex-def flex-cCenter flex-zBetween">
      <div class="y-desc" style=""></div>
      <div class="flex-def flex-cCenter">
        <el-button @click="addShow=true" type="primary" size="small">添加账号</el-button>
      </div>
    </div>
    <div style="margin-top: 1rem">
      <el-table :data="list" border style="width: 100%">
        <el-table-column prop="username" label="登陆账号" width="180" align="center"></el-table-column>
        <el-table-column label="使用平台" width="180" align="center">
          <template #default="s">
            <span v-if="s.row.uni_account">{{s.row.uni_account.name}}</span>
            <span v-else class="y-desc">未配平台</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="80" align="center">
          <template #default="s">
            <span class="y-desc">{{ statusParse(s.row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="上次活跃" width="150" align="center">
          <template #default="s">
            <span class="y-desc">{{ s.row.last_visit_time | date }}</span>
          </template>
        </el-table-column>
        <el-table-column label="上次活跃ip" width="120" align="center">
          <template #default="s">
            <span class="y-desc">{{ s.row.last_ip}}</span>
          </template>
        </el-table-column>
        <el-table-column label="注册时间" width="150" align="center">
          <template #default="s">
            <span class="y-desc">{{ s.row.created_at | date }}</span>
          </template>
        </el-table-column>
        <el-table-column label="来源" width="100" align="center">
          <template #default="s">
            <span class="y-desc">{{ registerParse(s.row.register_type) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="180" fixed="right">
          <template #default="s">
            <el-button @click="passwordEdit(s.row)" type="text" size="small">修改密码</el-button>
            <el-button @click="agentUni(s.row)" v-if="!s.row.uni_account" type="text" size="small">分配平台</el-button>
            <el-button @click="fail(s.row)" v-else type="text" size="small" style="color: red">禁止使用</el-button>
            <el-popconfirm style="margin-left: 1rem" title="确定删除吗？" @confirm="del(s.row)">
              <el-button slot="reference" type="text" size="small" style="color: red">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination @current-change="pageChange" :current-page="page" :total="total" :page-size="15"  style="padding: .5rem" background layout="total,prev, pager,next"></el-pagination>
        </template>
      </el-table>
      <el-dialog title="分配平台" :visible.sync="agentUniShow" width="30rem" @close="agentUniClose" destroy-on-close>
        <el-form ref="form" :model="agentUniForm" >
          <el-form-item label="平台名称">
            <y_choose_uni v-model="agentUniForm.uni_id" style="width: 100%"></y_choose_uni>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="agentUniShow = false">取 消</el-button>
          <el-button type="primary" @click="agentUniSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="添加账号" :visible.sync="addShow" width="30rem" @close="addClose">
        <el-form ref="form" :model="addForm" label-width="80px" style="padding-right: 2rem">
          <el-form-item label="登陆账号">
            <el-input v-model="addForm.username"></el-input>
          </el-form-item>
          <el-form-item label="登陆密码">
            <el-input type="password" show-password	v-model="addForm.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input type="password" show-password	v-model="addForm.re_password"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addShow = false">取 消</el-button>
          <el-button type="primary" @click="addSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="修改密码" :visible.sync="passwordEditShow" width="30rem" @close="passwordEditClose">
        <el-form ref="form" :model="passwordEditForm" label-width="80px" style="padding-right: 2rem">
          <el-form-item label="登陆账号">{{passwordEditForm.username}}</el-form-item>
          <el-form-item label="新密码">
            <el-input type="password" show-password	v-model="passwordEditForm.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input type="password" show-password v-model="passwordEditForm.re_password"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="passwordEditShow = false">取 消</el-button>
          <el-button type="primary" @click="passwordEditSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import {registerParse,statusParse} from "@/view/manager/tools";
import y_choose_uni from "@/components/y_choose_uni";
export default {
  name: "user",
  components:{
    y_choose_uni
  },
  data(){
    return{
      agentUniShow:false,
      agentUniForm:{
        uni_id:0,
        uid:0
      },
      passwordEditShow:false,
      passwordEditForm:{
        id:0,
        password:"",
        re_password:"",
      },
      addShow:false,
      addForm:{
        username:"",
        password:"",
        re_password:""
      },
      list:[],
      page:1,
      total:0,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    agentUni(item){
      this.agentUniShow = true;
      this.agentUniForm.uid = item.id;
    },
    agentUniSubmit(){
      this.$api.manager.bindau(this.agentUniForm).then(()=>{
        this.$message.success("操作成功");
        this.agentUniShow = false;
        this.load();
      })
    },
    agentUniClose(){
      this.agentUniForm = {
        uni_id:0,
        uid:0
      }
    },
    fail(item){
      this.$api.manager.accountFail({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    addSubmit(){
      this.$api.manager.accountAdd(this.addForm).then(()=>{
        this.$message.success("操作成功");
        this.addShow = false;
        this.load();
      })
    },
    addClose(){
      this.addForm = {
        username:"",
        password:"",
        re_password:""
      }
    },
    passwordEditSubmit(){
      this.$api.manager.accountResetPassword(this.passwordEditForm).then(()=>{
        this.$message.success("操作成功");
        this.passwordEditShow = false;
      });
    },
    passwordEdit(item){
      this.passwordEditForm.id = item.id;
      this.passwordEditForm.username = item.username;
      this.passwordEditShow = true;
    },
    passwordEditClose(){
      this.passwordEditForm = {
        id:0,
        password:"",
        re_password:"",
      }
    },
    statusParse(status){
      return statusParse(status);
    },
    registerParse(type){
      return registerParse(type)
    },
    del(item){
      this.$api.manager.accountDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load(){
      this.$api.manager.accountSearch({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(cur){
      this.page = cur;
    }
  }
}
</script>

<style scoped>
.num-border{
  border-right: 1px solid #ededed;
  margin-right: .5rem;
}
.num{
  padding: 0 .5rem;
  font-size: 1rem;
  font-weight: 600;
}
</style>