
export function statusParse(status){
    let str = "未知状态";
    switch (parseInt(status)){
        case 1:
            str = "注册审核";
            break;
        case 2:
            str = "正常使用";
            break;
        case 3:
            str = "禁用"
            break;
    }
    return str;
}

export function registerParse(type){
    let str = "未知来源";
    switch (parseInt(type)){
        case 0:
            str = "管理员创建";
            break;
        case 1:
            str = "网站注册";
            break;
    }
    return str;
}